import React from 'react';
import classNames from 'classnames';

import MyComponent from "../../base/MyComponent";
import AnimatedElement from "../../components/AnimatedElement/AnimatedElement";
import InstructionCard from "../../components/InstructionCard";
import Button from "../../components/Button/Button";

import "./VotingExercise.scss";

import LoveSmileImg from './images/love-smile.svg'
import HugeSmileImg from './images/huge-smile.svg'
import SmileImg from './images/smile.svg'
import MehImg from './images/meh.svg'
import FrownImg from './images/frown.svg'
import HugeFrownImg from './images/huge-frown.svg'

const IMAGES = {
  6: LoveSmileImg,
  5: HugeSmileImg,
  4: SmileImg,
  3: MehImg,
  2: FrownImg,
  1: HugeFrownImg,
};

const STATES = {
  STARTING: 0,
  STARTED: 1,
  FINISHED: 2,
};

export default class VotingExercise extends MyComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: undefined,
    };

    MyComponent.myConstructor(props, this.state)
  }

  render() {
    const question = this.props.questions[0];
    const {selected} = this.state;

    return <>
      <AnimatedElement
        className="VotingExercise"
        visible={!this.inState(STATES.FINISHED)}
      >
        <InstructionCard key="instructions" visible={!this.inState(STATES.FINISHED)}
          mainText={question.content}
        />
        <VotingExercise.Answers
          selectedId={selected}
          selectAnswer$={this.selectAnswer$}
        />
        <Button big
          disabled={selected === undefined}
          onClick={this.vote}
        >
          Zagłosuj
        </Button>
      </AnimatedElement>
    </>;
  }

  static Answers = ({selectedId, selectAnswer$}) => {
    return <div className="VotingExercise-Answers">
      {[6,5,4,3,2,1].map(answerId => (
        <Button key={answerId}
          className={classNames(
            "voting-smiley",
            `vote-${answerId}`,
            {"selected": selectedId === answerId}
          )}
          onClick={selectAnswer$(answerId)}
        >
          <img src={IMAGES[answerId]} alt="Świetne" />
        </Button>
      ))}
    </div>
  };

  selectAnswer$ = (selected) => () => {
    this.setState({
      selected,
    })
  };

  vote = () => {
    this.setCurrentState(STATES.FINISHED, this.finished, 1000);
  };

  finished = () => {
    const {selected} = this.state;
    const answer = this.props.questions[0].answers[0];

    this.finish(true, {
      chosenAnswerData: {
        id: answer.id,
        data: {
          vote: selected,
        }
      },
    })
  }
}